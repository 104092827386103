import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../utils/theme';
import hexagonTop from '../images/sky-blue-hexagon-top.svg';
import raptor from '../images/Raptor.svg';

const StyledTeamCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 210px;
  height: 328px;
  overflow: hidden;
  background-color: #67787D;
`;

const TeamImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 215px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TeamBio = styled.div`
  position: relative;
  background-color: ${theme.SKY_BLUE};
  color: ${theme.WHITE_COLOR};
  padding: 16px 24px;
  flex: 1;
`;

const TopWrapper = styled.div`
  position: relative;
  margin: 164px -5px -5px;
`;

const BioWrapper = styled.div`
  position: relative;
  top: -16px;
  /* margin-bottom: -16px; */
`;

interface MarginProp {
  margin: string;
}

const BioName = styled.p<MarginProp>`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 800;
  font-size: 18px;
  line-height: 1.524;
  margin: 0 0 8px;
`;

const BioTitle = styled.p<MarginProp>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 16px;
  line-height: 1.222;
`;

interface TeamCardProps {
  imgSrc: string | undefined;
  bioName: string;
  bioTitle: string;
}

const TeamCard: React.FC<TeamCardProps> = ({ imgSrc, bioName, bioTitle }) => {
  const returnCloudinaryUrl = () => `https://res.cloudinary.com/rapptr/image/upload/c_fill,g_auto:face,h_400,w_400/f_webp/q_auto/RapptrSite/Headshots/${imgSrc}.webp`;
  if (bioName === "Zach Stone") {
    console.log("here");
    console.log(imgSrc);
  }
  return (
    <StyledTeamCard>
        <TeamImage>
          <img
            src={imgSrc ? returnCloudinaryUrl() : raptor}
          alt={bioName ? bioName : ''}
          />
        </TeamImage>
      <TopWrapper>
        <img src={hexagonTop} alt="" />
      </TopWrapper>
      <TeamBio>
        <BioWrapper>
          {bioName && <BioName margin={`16px 0`}>{bioName}</BioName>}
          {bioTitle && <BioTitle margin={`0 0 20px`}>{bioTitle}</BioTitle>}
        </BioWrapper>
      </TeamBio>
    </StyledTeamCard>
  );
};

export default TeamCard;
