import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import { NewAppTitle, NewAppText, NewAppButton } from '../components/ui';
// import { LostPageLogo, LostPageError } from '../assets';
import { mobileSize } from '../utils';
import styled from 'styled-components';
import { IndexLayout } from '../layouts/';
import Helmet from 'react-helmet';
import { NavBar } from '../components/segments/NavBar';
import DarkFooter from '../components/segments/DarkFooter';
import { theme } from '../utils/theme';
import { desktopSize, laptopSize } from '../utils/variables';
import LetsConnectSection from '../components/segments/LetsConnectSection';
import { motion } from 'framer-motion';

import rapptrMug from '../images/about/Mug.jpg';
import rapptrOffice from '../images/about/Office.jpg';
import clientsFrame from '../images/about/Frame1.png';
import Team from '../static/teamMembers';
import TeamCard from '../components/TeamCard';

export default () => {
  const [showAll, setShowAll] = useState(false);
  return (
    <IndexLayout>
      <Helmet>
        <title>Rapptr Labs | About</title>
        <link rel="stylesheet" href="https://use.typekit.net/yxm3bov.css" />
      </Helmet>
      <NavBar alwaysOpaque invert />
      <PageContainer>
        <TextContainer1>
          {/* <Wrapper> */}
          {/* <PageLabel>404 Page</PageLabel> */}
          <PageDescriptionText>
            Building world-class apps and mobile experiences isn’t just what we do.
          </PageDescriptionText>
          <PageMainText>It’s who we are.</PageMainText>
        </TextContainer1>
        {/* </Wrapper> */}
        <AbsDiv1 />
        {/* <AbsDiv2 /> */}
      </PageContainer>
      <FirstSection>
        {/* <JourneyTitle>Our Approach to Your Product Journey</JourneyTitle> */}
        <TextContainer2>
          <SectionTitle invert>Who We Are</SectionTitle>
          <SectionText invert>
            Located just outside of NYC, we’re a high-energy, diverse, and passionate team that
            brings our unique skills and perspectives to the table to design apps and digital
            products that delight our customers.
          </SectionText>
        </TextContainer2>
        <MugImg src={rapptrMug} alt="Rapptr Mug" />
      </FirstSection>
      <SecondSection>
        <SectionTitle>What We Do</SectionTitle>
        <SectionText center style={{ maxWidth: '1044px' }}>
          From app strategy, design and development to launch and ongoing tailored support, we are
          with you every step of the way – continuously refining and evolving your product.
        </SectionText>
        <Link to="/approach/">
          <ApproachButton>See Our Approach</ApproachButton>
        </Link>
        <AbsDiv3 />
        <AbsDiv4 />
        {/* <AbsDiv5 /> */}
        <ClientsImg src={clientsFrame} alt="Our clients" />
      </SecondSection>
      <ThirdSection>
        <OfficeImg src={rapptrOffice} alt="Rapptr Labs Office" />
        <TextContainer3>
          <SectionTitle invert>What Makes Us Rapptr</SectionTitle>
          <SectionText invert>
            Your success is our success. That’s why we empower everyone from funded start-ups to
            established brands with a quick, adaptable, and agile path to launching their digital
            products.
          </SectionText>
          <ConnectButton onClick={() => navigate('/contact-us')}>Connect With Us</ConnectButton>
        </TextContainer3>
        <AbsDiv6 />
      </ThirdSection>
      <TeamSection id="our-team">
        <TeamTextContainer>
          <SectionTitle
            invert
            center
            style={{
              maxWidth: '800px',
            }}
          >
            World-Class Apps Require a World-Class Team
          </SectionTitle>
          <SectionText
            invert
            center
            style={{
              maxWidth: '825px',
            }}
          >
            Meet the people at Rapptr that turn amazing ideas into digital products that people love
            to use.
          </SectionText>
        </TeamTextContainer>
        <TeamGrid $showAll={showAll}>
          {Team.map((member) => (
            <TeamCard imgSrc={member.imgUrl} bioName={member.name} bioTitle={member.position} />
          ))}
        </TeamGrid>
        <ShowAll show={!showAll} onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Show Less' : 'Show All'}
        </ShowAll>
        <AbsDiv7 />
      </TeamSection>
      <HiringSegment>
        <HiringGrid>
          <HiringDiv1>
            <SectionTitle invert>We’re Hiring</SectionTitle>
            <SectionText invert>
              You deserve to love what you do. Why not do it with Rapptr?
            </SectionText>
            <a href="http://careers.rapptrlabs.com/">
              <JobsButton>See Our Job Openings</JobsButton>
            </a>
          </HiringDiv1>
          <div>
            <QuoteText>
              Rapptr knows that the success of the individual facilitates the thriving of the whole
              team. We create excellent products because we work together in a healthy, creative
              environment where challenges mean learning and experience means efficiency.
            </QuoteText>
            <QuoteAuthor>Bekah Hanson</QuoteAuthor>
            <QuoteAuthorTitle>Sr. Product Designer</QuoteAuthorTitle>
          </div>
        </HiringGrid>
      </HiringSegment>
      <LetsConnectSection />
      <DarkFooter />
    </IndexLayout>
  );
};

const HiringDiv1 = styled.div`
  @media ${laptopSize} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const QuoteAuthor = styled(NewAppText)`
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  text-align: center;
  color: #7e8e96;
  margin-bottom: 7px;
`;

const QuoteAuthorTitle = styled(NewAppText)`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #7e8e96;
  margin-bottom: 0px;
  @media ${laptopSize} {
    margin-bottom: 80px;
  }
`;

const QuoteText = styled(NewAppTitle)`
  position: relative;
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #7e8e96;
  margin-bottom: 27px;
  margin-top: 100px;

  ::before {
    content: '“';
    position: absolute;
    left: 0px;
    top: 0;
    font-family: ${theme.SECONDARY_FONT};
    font-size: 288px;
    color: #e8e8e9;
  }
  ::after {
    content: '”';
    position: absolute;
    /* right: -100px; */
    right: 0px;
    bottom: -180px;
    font-family: ${theme.SECONDARY_FONT};
    font-size: 288px;
    color: #e8e8e9;
  }

  @media ${laptopSize} {
    margin-bottom: 40px;
    margin-top: 80px;
    padding: 0px;
    ::before {
      left: -10px;
      /* position: relative; */
    }
    ::after {
      /* top: auto; */
      bottom: -190px;
      right: 10px;
    }
  }
  @media ${mobileSize} {
    ::after {
      bottom: -300px;
    }
  }
`;

const HiringGrid = styled.div`
  display: grid;
  max-width: 1232px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  grid-template-columns: 450fr 610fr;
  grid-gap: 50px;
  justify-content: center;
  align-items: center;
  @media ${laptopSize} {
    grid-template-columns: 1fr;
  }
`;

const HiringSegment = styled.div`
  min-height: 591px;
  width: 100%;
  background-color: ${theme.WHITE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  @media ${laptopSize} {
  }
`;

const TeamTextContainer = styled.div`
  /* max-width: 825px; */
  margin-top: 60px;
`;

const TeamGrid = styled.div<{ $showAll: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  grid-gap: 40px;
  justify-items: center;
  align-items: center;

  width: 100%;
  max-width: 1248px;
  margin: 100px 0;
  @media ${mobileSize} {
    margin-bottom: 40px;
    // (TeamCard_height + flex_gap) * num_cards_to_show - end_flex_gap
    height: ${(props) => (props.$showAll ? 'auto' : 'calc((328px + 40px) * 5 - 40px)')};
    overflow: hidden;
  }
`;

const ShowAll = styled(NewAppText as any)`
  display: none;
  position: relative;
  color: ${theme.FOOTER_GRAY};
  cursor: pointer;
  ::before {
    content: '';
    position: absolute;
    left: -200%;
    top: 50%;
    display: block;
    width: 100px;
    height: 1px;
    background-color: ${theme.FOOTER_GRAY};
  }

  ::after {
    content: '';
    position: absolute;
    right: -200%;
    top: 50%;
    display: block;
    width: 100px;
    height: 1px;
    background-color: ${theme.FOOTER_GRAY};
  }

  @media ${mobileSize} {
    display: block;
  }
`;

const OfficeImg = styled.img`
  position: relative;
  /* height: 900px; */
  margin-top: -205px;
  z-index: 1000;
  -webkit-clip-path: polygon(0 19%, 77% 15%, 100% 85%, 0 100%);
  clip-path: polygon(0 19%, 77% 15%, 100% 85%, 0 100%);
  background-color: blue;
  @media ${desktopSize} {
    height: 820px;
    max-width: 575px;
    margin-top: -155px;
    -webkit-clip-path: polygon(0 19%, 77% 15.5%, 100% 86.9%, 0 100%);
    clip-path: polygon(0 19%, 77% 15.5%, 100% 86.9%, 0 100%);
  }
  @media ${laptopSize} {
    -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 85%, 0% 100%);
    clip-path: polygon(0 25%, 100% 25%, 100% 85%, 0% 100%);
    max-width: none;
    /* margin-top: -200px; */
    height: auto;
    width: 100%;
    margin-top: -30vw;
    z-index: 1;
    overflow: hidden;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 18% 100%, 0 90%);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 18% 100%, 0 90%);
  }
`;

const ConnectButton = styled(NewAppButton)`
  border: none;
  background-color: ${theme.MAIN_DARK_BLUE};
  width: fit-content;
  margin-top: 30px;
  :hover {
    background-color: ${theme.SKY_BLUE};
  }
`;

const ClientsImg = styled.img`
  /* margin-bottom: -250px; */
  z-index: 4;
  @media ${mobileSize} {
    /* margin-bottom: -100px; */
  }
  @media ${laptopSize} {
    z-index: 0;
  }
`;

const ApproachButton = styled(NewAppButton)`
  background-color: ${theme.SKY_BLUE};
  border: none;
  margin-top: 20px;
  @media ${mobileSize} {
    margin: 20px auto;
  }
`;

const JobsButton = styled(NewAppButton)`
  background-color: ${theme.SKY_BLUE};
  border: none;
  margin-top: 20px;
  :hover {
    color: ${theme.MAIN_DARK_BLUE};
  }
  @media ${laptopSize} {
    margin: 20px auto;
  }
`;

const MugImg = styled.img`
  position: relative;
  height: 900px;
  @media ${desktopSize} {
    height: 700px;
  }
  @media ${laptopSize} {
    height: auto;
    width: 100%;
    margin-top: -30vw;
    z-index: 1;
  }
`;

const TextContainer2 = styled(motion.div)`
  /* margin-left: 33vw; */
  height: 900px;
  min-height: 900px;
  max-height: 900px;
  max-width: 713px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  position: relative;
  ::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 900px 200px 0 0;
    border-color: ${theme.WHITE_COLOR} transparent transparent transparent;
    /* left: 0px; */
    right: -199px;
    top: 0;
    z-index: 2;
  }
  @media ${desktopSize} {
    height: 700px;
    min-height: 700px;
    max-height: 700px;
    /* padding-top: 24px; */
    ::after {
      border-width: 700px 200px 0 0;
    }
  }
  @media ${laptopSize} {
    min-width: 100vw;
    max-width: 100vw;
    width: 100vw;
    height: auto;
    min-height: auto;
    max-height: auto;
    padding-top: 150px;
    padding-bottom: 50px;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0px;
    z-index: 2;
    background-color: ${theme.WHITE_COLOR};
    ::after {
      display: none;
    }
  }
`;

const TextContainer3 = styled.div`
  margin-right: auto;

  padding-left: 40px;
  height: fit-content;
  /* min-height: 900px; */
  max-width: 713px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: transparent;
  z-index: 5;
  /* ::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 150px 900px 0;
    border-color: transparent ${theme.WHITE_COLOR} transparent transparent;
    left: -150px;
    bottom: 0;
    z-index: 2;
  } */
  @media ${desktopSize} {
    margin-top: 0px;
  }
  /* @media ${laptopSize} {
    background-color: ${theme.WHITE_COLOR};
  } */
  @media ${laptopSize} {
    min-width: 100vw;
    max-width: 100vw;
    width: 100vw;
    height: auto;
    min-height: auto;
    max-height: auto;
    padding-top: 80px;
    padding-bottom: 50px;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0px;
    z-index: 5;
    align-items: center;
    background-color: ${theme.WHITE_COLOR};
    ::after {
      display: none;
    }
  }
`;

const TextContainer1 = styled.div`
  /* width: 835px; */
  width: fit-content;
  z-index: 2;
  @media ${laptopSize} {
    width: 100%;
  }
`;

interface TextStyleProps {
  invert?: boolean;
  center?: boolean;
}

const SectionTitle = styled(NewAppTitle)<TextStyleProps>`
  color: ${(props) => (props.invert ? theme.MAIN_DARK_BLUE : theme.WHITE_COLOR)};
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 53px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  margin: 0px;
  margin-bottom: 8px;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const SectionText = styled(NewAppText)<TextStyleProps>`
  color: ${(props) => (props.invert ? theme.MAIN_DARK_BLUE : theme.WHITE_COLOR)};
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 1.4375;
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  margin: 0px;
  @media ${desktopSize} {
    font-size: 26px;
  }
  @media ${laptopSize} {
    text-align: center;
  }
`;

const ProcessSubheading = styled(NewAppText)`
  font-weight: 300;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  max-width: 940px;
  margin-bottom: 62px;
`;

const SecondSection = styled.div`
  /* min-height: 1000px; */
  background-color: ${theme.MAIN_DARK_BLUE};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 100px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  @media ${desktopSize} {
    padding-top: 160px;
  }
  @media ${laptopSize} {
    min-height: fit-content;
    height: fit-content;
    padding-top: 100px;
    padding-bottom: 0px;
    /* padding-bottom: 150px; */
  }
`;

const TeamSection = styled.div`
  /* min-height: 1000px; */
  background-color: ${theme.LIGHT_GREY};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 0px;
  padding-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
  @media ${laptopSize} {
    min-height: fit-content;
    height: fit-content;

    padding-bottom: 150px;
  }
`;

interface SelectedProp {
  selected: boolean;
}

const JourneyStepButton = styled.div<SelectedProp>`
  width: 63px;
  height: 10px;
  background-color: ${(props) => (props.selected ? theme.SKY_BLUE : theme.GREY_BLUE)};
  margin-right: 14px;
  cursor: pointer;
  z-index: 6;
  transition: background-color 250ms ease-in-out;
  :hover {
    background-color: ${(props) => (props.selected ? theme.SKY_BLUE : theme.LIGHT_BLUE)};
  }
  @media ${laptopSize} {
    width: 100%;
  }
  :last-child {
    margin-right: 0px;
  }
`;

const JourneyStepDescriptionPrimary = styled(NewAppText)`
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: ${theme.MAIN_DARK_BLUE};
  margin: 0px;
  margin-bottom: 22px;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const JourneyTitle = styled(NewAppTitle)`
  color: ${theme.MAIN_DARK_BLUE};
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  @media ${laptopSize} {
    font-weight: 800;
    text-align: center;
  }
`;

const ProcessTitle = styled(JourneyTitle)`
  color: ${theme.WHITE_COLOR};
`;

const FirstSection = styled.div`
  /* padding-top: 150px; */
  /* padding-bottom: 60px; */
  /* padding-left: 30px;
  padding-right: 30px; */
  min-height: 800px;
  background-color: ${theme.WHITE_COLOR};
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
  /* @media ${desktopSize} {
    min-height: 775px;
  } */
  @media ${mobileSize} {
  }
  @media ${laptopSize} {
    flex-direction: column;
    /* padding-top: 100px; */
  }
`;

const ThirdSection = styled.div`
  /* padding-top: 150px; */
  /* padding-bottom: 60px; */
  /* padding-left: 30px;
  padding-right: 30px; */
  min-height: 655px;
  background: ${theme.WHITE_COLOR};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* flex-direction: column; */
  position: relative;
  overflow: visible;
  @media ${laptopSize} {
    flex-direction: column-reverse;
    background: ${theme.LIGHT_GREY};

    overflow: visible;
    /* padding-top: 100px; */
    /* z-index: 1; */
  }
`;

const PageDescriptionText = styled(NewAppTitle)`
  max-width: 880px;

  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 53px;
  /* or 118% */
  margin: 0px;
  text-align: center;
  @media ${mobileSize} {
    font-size: 45px;
    line-height: 53px;
  }
`;

const PageMainText = styled(NewAppTitle)`
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 75px;
  text-align: center;
  color: ${theme.SKY_BLUE};
  @media ${mobileSize} {
    font-size: 45px;
    line-height: 53px;
    white-space: pre-wrap;
  }
`;

const PageLabel = styled(NewAppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const AbsDiv1 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  /* border-width: 150px 0 0 27vw; */
  border-width: 150px 100vw 0 0;
  border-color: ${theme.BACKGROUND_COLOR} transparent transparent transparent;
  bottom: -149px;
  left: 0;
  z-index: 3;
  @media ${mobileSize} {
    border-width: 60px 100vw 0 0;
    bottom: -59px;
  }
`;

const AbsDiv2 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 80vw 300px 0;
  border-color: transparent ${theme.BACKGROUND_COLOR} transparent transparent;
  bottom: -300px;
  right: 0;
  z-index: 3;
  @media ${desktopSize} {
    border-width: 0 80vw 150px 0;
    bottom: -150px;
  }
  @media ${laptopSize} {
    border-width: 0 100vw 50px 0;
    bottom: -50px;
  }
`;

const AbsDiv3 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 400px 300px 0 0;
  border-color: ${theme.WHITE_COLOR} transparent transparent transparent;
  top: -150px;
  left: 0;
  z-index: 4;
  @media ${laptopSize} {
    display: none;
  }
`;

const AbsDiv4 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 150px 0 0 100vw;
  border-color: transparent transparent transparent ${theme.MAIN_DARK_BLUE};
  top: -149px;
  right: 0;
  z-index: 3;
  @media ${laptopSize} {
    border-width: 50px 0 0 100vw;
    top: -49px;
  }
`;

const AbsDiv5 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 150px 100vw 0 0;
  border-color: ${theme.MAIN_DARK_BLUE} transparent transparent transparent;
  bottom: -150px;
  right: 0;
  z-index: 3;
  @media ${laptopSize} {
    border-width: 100px 100vw 0 0;
    bottom: -100px;
  }
`;

const AbsDiv6 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 6.4516129vw 100vw;
  border-color: transparent transparent ${theme.WHITE_COLOR} transparent;
  top: -6.4vw;
  right: 0;
  z-index: 6;
  @media ${desktopSize} {
    top: -6.35vw;
  }
  @media ${laptopSize} {
    border-width: 0 0 100px 100vw;
    top: -99px;
    z-index: 8;
  }
`;

const AbsDiv7 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 18.6258278vw 100vw;
  border-color: transparent transparent ${theme.LIGHT_GREY} transparent;
  top: -18.6258278vw;
  right: 0;
  z-index: 2;
  @media ${laptopSize} {
    border-width: 50px 0 0 100vw;
  }
`;

const PageContainer = styled.div`
  position: relative;
  min-height: 400px;
  width: 100%;
  background-color: ${theme.BACKGROUND_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  padding-top: 60px;
  margin-top: 120px;
  @media ${laptopSize} {
    margin-top: 80px;
  }
`;
